import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18next from 'i18next'
import { bindActionCreators } from 'redux';
import { expandCreateDS, expandCreateDSTSVerify, setDSSignatureType, setAddDsToExist, addDataTs,
		addDsTs, dsTsCheck, setDuplicateSign, setCadesType, expandCreateDSCadesType, setCreateDSOptionsExpand,
		setShowUpgradeCard, setGenerateQRDS, setCleanUpForm } from '../actions/localStates'

class CreateDSOptionsContainer extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
			collapseDSTypeShow: "",
			collapseTSVerifyShow: "",
			collapseCadesTypeShow: "",
			collapseCreateDSOptionsShow: "",
			showUpgradeDSCard: false
	    }

	    this.dsTypeHendler = this.dsTypeHendler.bind(this)
	    this.addDsToExistHendler = this.addDsToExistHendler.bind(this)
	    this.addDataTsHendler = this.addDataTsHendler.bind(this)
	    this.addDsTsHendler = this.addDsTsHendler.bind(this)
	    this.dsTsCheckHendler = this.dsTsCheckHendler.bind(this)
	    this.cadesTypeHendler = this.cadesTypeHendler.bind(this)
	    this.showUpgradeCardHandler = this.showUpgradeCardHandler.bind(this)
	    this.setGenerateQRHandler = this.setGenerateQRHandler.bind(this)
	}

	expandCreateDSOptions() {
		this.props.actions.setCreateDSOptionsExpand(this.props.expandedCardsReducer.createOptionsExpanded)
		this.props.expandedCardsReducer.createOptionsExpanded ? this.setState({collapseCreateDSOptionsShow: "card-body collapse"}) : this.setState({collapseCreateDSOptionsShow: "card-body collapse show"})
	}

	expandCreateDS() {
		this.props.actions.expandCreateDS(this.props.defaultState.expandCreateDSType)
		this.props.defaultState.expandCreateDSType ? this.setState({collapseDSTypeShow: "collapse"}) : this.setState({collapseDSTypeShow: "collapse show"})
	}

	expandCreateDSTSVerify() {
		this.props.actions.expandCreateDSTSVerify(this.props.defaultState.expandCreateDSTSVerify)
		this.props.defaultState.expandCreateDSTSVerify ? this.setState({collapseTSVerifyShow: "collapse"}) : this.setState({collapseTSVerifyShow: "collapse show"})
	}

	expandCreateDSCadesType() {
		this.props.actions.expandCreateDSCadesType(this.props.defaultState.expandCreateDSCadesType)
		this.props.defaultState.expandCreateDSCadesType ? this.setState({collapseCadesTypeShow: "collapse"}) : this.setState({collapseCadesTypeShow: "collapse show"})
	}

	componentDidMount() {
		this.props.defaultState.expandCreateDSType ? this.setState({collapseDSTypeShow: "collapse show"}) : this.setState({collapseDSTypeShow: "collapse"})
		this.props.defaultState.expandCreateDSTSVerify ? this.setState({collapseTSVerifyShow: "collapse show"}) : this.setState({collapseTSVerifyShow: "collapse"})
		this.props.defaultState.expandCreateDSCadesType ? this.setState({collapseCadesTypeShow: "collapse show"}) : this.setState({collapseCadesTypeShow: "collapse"})
		this.props.expandedCardsReducer.createOptionsExpanded ? this.setState({collapseCreateDSOptionsShow: "card-body collapse show"}) : this.setState({collapseCreateDSOptionsShow: "card-body collapse"})
	}

	dsTypeHendler(e) {
		this.props.actions.setDSSignatureType(e.target.value)
	}

	cadesTypeHendler(e) {
		this.props.actions.setCadesType(e.target.value)
	}

	addDsToExistHendler(e) {
		e.target.checked ? this.props.actions.setAddDsToExist("alreadySigned") : this.props.actions.setAddDsToExist("notSignedBefore")
		this.props.actions.setCleanUpForm(true)
		this.props.actions.setDuplicateSign(e.target.checked)
	}

	setGenerateQRHandler (e) {
		this.props.actions.setGenerateQRDS(e.target.checked)
		this.props.actions.setCleanUpForm(true)
	}

	showUpgradeCardHandler(e) {
		this.props.actions.setShowUpgradeCard(e.target.checked)
	}

	addDataTsHendler(e) {
		this.props.actions.addDataTs(e.target.checked, e.target.checked.toString())
	}

	addDsTsHendler(e) {
		this.props.actions.addDsTs(e.target.checked, e.target.checked.toString())
	}

	dsTsCheckHendler(e) {
		this.props.actions.dsTsCheck(e.target.value)
	}

	render() {
		const mode = (this.props.connectionStatus.cloudConnection || this.props.connectionStatus.uccConnection) ? parseInt(this.props.pkLocalState.selectedMode, 10) : 0;
		return (
			<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" style={{paddingBottom: "10px"}}>
				<div className="card">
	            <div className="card-header" onClick={this.expandCreateDSOptions.bind(this)}>
					{i18next.t("ds:dsCreateParamsTitle")}
	            </div>
	            <div className={this.state.collapseCreateDSOptionsShow}>
	            	<div>
	                    <label style={{"cursor": "pointer"}} data-toggle="collapse" onClick={this.expandCreateDS.bind(this)} role="button" aria-expanded={this.props.defaultState.expandCreateDSType} aria-controls="dsvLeftParamAreaDsType">
	                        {
								this.props.defaultState.expandCreateDSType ? <i className="fa fa-chevron-down" aria-hidden="true"></i>
									: <i className="fa fa-chevron-right" aria-hidden="true"></i>
							}
	                        &nbsp;
	                        <span id="dsvDsTypeTitle">{i18next.t("ds:dsTypeTitle")}</span>
	                    </label>
	                    <div id="dsvLeftParamAreaDsType" className={this.state.collapseDSTypeShow}>
	                        <div className="form-check">
	                            <div className="radio ml-tool-panel">
	                                <label title={i18next.t("ds:attachedDsTooltip")}>
	                                    <input className="form-check-input" name="dsvSignatureType" value="attached" type="radio" checked={this.props.defaultState.signatureType === 'attached'} onChange={this.dsTypeHendler} />
	                                    <span className="form-check-label" id="dsvAttachedDsTitle">{i18next.t("ds:attachedDsTitle")}</span>
	                                </label>
	                            </div>
	                            <div className="radio ml-tool-panel">
	                                <label title={i18next.t("ds:detachedDsTooltip")}>
	                                    <input className="form-check-input" name="dsvSignatureType" value="detached" type="radio" checked={this.props.defaultState.signatureType === 'detached'} onChange={this.dsTypeHendler} />
	                                    <span className="form-check-label" id="dsvDetachedDsTitle">{i18next.t("ds:detachedDsTitle")}</span>
	                                </label>
	                            </div>
	                            
	                        </div>
	                    </div>
	                    <div>
		                	<label style={{"cursor": "pointer"}} data-toggle="collapse" href={null} onClick={this.expandCreateDSCadesType.bind(this)} aria-expanded={this.props.defaultState.expandCreateDSCadesType} aria-controls="dscLeftParamAreaDsAddon" role="button">
		                        {
									this.props.defaultState.expandCreateDSCadesType ? <i className="fa fa-chevron-down" aria-hidden="true"></i>
										: <i className="fa fa-chevron-right" aria-hidden="true"></i>
								}
		                        &nbsp;
		                        <span>{i18next.t("ds:standartDSTitle")}</span>
		                    </label>
		                    <div className={this.state.collapseCadesTypeShow}>
		                    	<div className="form-check">
			                        <div className="radio ml-tool-panel">
			                            <label title={i18next.t("ds:dsBaseTooltip")}>
			                                <input className="form-check-input" value="CAdESBES" type="radio" checked={this.props.defaultState.cadesType === 'CAdESBES'} onChange={this.cadesTypeHendler} />
			                                <span className="form-check-label">{i18next.t("ds:dsBaseTitle")}</span>
			                            </label>
			                        </div>
			                        <div className="radio ml-tool-panel">
			                            <label title={i18next.t("ds:dsLongTooltip")}>
			                                <input className="form-check-input" value="CAdESXLong" type="radio" checked={this.props.defaultState.cadesType === 'CAdESXLong'} onChange={this.cadesTypeHendler} />
			                                <span className="form-check-label">{i18next.t("ds:dsLongTitle")}</span>
			                            </label>
			                        </div>
			                    </div>
		                    </div>
		                </div>
		                {
                        	this.state.showUpgradeDSCard
                        	? <div className="checkbox ml-tool-panel">
                                <label title={i18next.t("ds:modifyDSTooltip")}>
                                    <input className="form-check-input" type="checkbox" checked={this.props.defaultState.showUpgradeCard === true} onChange={this.showUpgradeCardHandler} />
                                    <span className="form-check-label" id="dscAddDsToExistingOneTitle">{i18next.t("ds:modifyDSTitle")}</span>
                                </label>
                            </div>
                            : null
                        }
                        {
                        	mode !== 4
                        	? <div className="checkbox ml-tool-panel">
	                            <label title={i18next.t("ds:addDsToExistingOneTooltip")} style={{"cursor": "pointer", paddingLeft: "7px"}}>
	                                <input className="form-check-input" id="dscDataToSignQualifier" type="checkbox" checked={this.props.defaultState.dataToSignQualifier === 'alreadySigned'} onChange={this.addDsToExistHendler} />
	                                <span className="form-check-label" id="dscAddDsToExistingOneTitle">{i18next.t("ds:addDsToExistingOneTitle")}</span>
	                            </label>
	                        </div>
	                        : null
	                    }
		                {
                        	!this.props.base.isVtco && this.props.base.qrCodeUrl !== undefined && this.props.base.qrCodeUrl.length > 0
                        	? <div className="radio ml-tool-panel">
                                <label title={i18next.t("ds:generateQRTitle")} style={{"cursor": "pointer", paddingLeft: "7px"}}>
                                    <input className="form-check-input" type="checkbox" checked={this.props.defaultState.generateQR === true} onChange={this.setGenerateQRHandler} />
                                    <span className="form-check-label" id="dscAddDsToExistingOneTitle">{i18next.t("ds:generateQRTitle")}</span>
                                </label>
                            </div>
                            : null
                        }
	                </div>
	            </div>
	        </div>
	    </div>
		)
	}
}

function mapStateToProps(state) {
    return {
    	base: state.base,
    	language: state.localesReducer.language,
        localesReducer: state.localesReducer,
        defaultState: state.createDSOptionsReducer,
        pkLocalState: state.privateKeyReducer,
        mobileModeReducer: state.mobileModeReducer,
        expandedCardsReducer: state.expandedCardsReducer,
        connectionStatus: state.connectionStatusReucer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    expandCreateDS,
	    expandCreateDSTSVerify,
	    setDSSignatureType,
	    setAddDsToExist,
	    addDataTs,
	    addDsTs,
	    dsTsCheck,
	    setDuplicateSign,
	    setCadesType,
	    expandCreateDSCadesType,
	    setCreateDSOptionsExpand,
	    setShowUpgradeCard,
	    setGenerateQRDS,
	    setCleanUpForm
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDSOptionsContainer);